<script>
export default {
  name: 'HorizontalMenu',
  props: {
    options: {
      type: Array
    },
    value: {
      type: [String, Number]
    }
  },
  data () {
    return {
      mutableValue: 0
    }
  },
  methods: {
    pushTo (to, index) {
      this.$emit('input', index)
      this.mutableValue = index
      this.$router.push(to).catch(() => { })
    }
  },
  mounted () {
    setTimeout(() => {
      this.options.forEach((opt, index) => {
        if (opt.to.name === this.$route.name && (opt.to.name !== 'individual.discovery.index.search' && opt.to.name !== 'aggregator.discovery.index.search')) {
          this.$emit('input', index)
          this.mutableValue = index
        }
      })
    }, 100)
  }
}
</script>
<template>
  <div class="horizontal-menu--container d-flex flex-column d-sm-inline-block">
      <v-btn v-for="(button, index) in options" class="horizontal-menu--button transform-unset" text :class="{'active': value === index }" :key="button.to.name + button.text" @click="pushTo(button.to, index)">
        <v-icon size="18" :color="value === index ? '#454545' : '#7E7D77'" class="mr-2" v-if="button.icon">{{ button.icon }}</v-icon>
        {{ $t(button.text) }}
      </v-btn>
  </div>
</template>
<style lang="scss">
.horizontal-menu--container {
  display: inline-block;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 4px;
  .horizontal-menu--button {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #7E7D77;
    &.active {
      color: #454545;
      background: #FFFFFF;
      box-shadow: 0px 1px 1px rgba(66, 66, 66, 0.08), 0px 1px 3px 1px rgba(66, 66, 66, 0.16);
    }
  }
}
</style>
