<script>
import InputField from '@/components/general/InputField'
import HorizontalMenu from '@/components/general/HorizontalMenu'
import AppliedFilters from '@/components/discovery/AppliedFilters'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import ModalAdvancedSearch from '@/components/discovery/ModalAdvancedSearch'
import ModalSearch from '@/components/discovery/ModalSearch'
import ModalContentListAssociationVue from '../components/contents/ModalContentListAssociation.vue'
import ModalContentDetailsVue from '../components/contents/ModalContentDetails.vue'

export default {
  name: 'IndividualDiscovery',
  components: {
    InputField,
    AppliedFilters,
    Breadcrumbs,
    ModalAdvancedSearch,
    ModalSearch,
    HorizontalMenu,
    ModalContentListAssociationVue,
    ModalContentDetailsVue
  },
  data () {
    return {
      searchingApply: false,
      showAdvancedSearch: false,
      textualSearch: null,
      selectedFilter: '0',
      searchParameters: {},
      selectedMenu: 0,
      showAddList: false,
      showContentModal: false,
      contentId: '',
      filteredListLength: -1
    }
  },
  created () {
    this.selectedFilter = this.$route.query.type === undefined ? '0' : this.$route.query.type === 'positions' ? '0' : this.$route.query.type === 'contents' ? '1' : '2'
    this.selectedMenu = Number(this.selectedFilter)
    const searchType = this.selectedFilter === '0' ? 'positions' : this.selectedFilter === '1' ? 'contents' : 'courses'

    if (this.$route.name.includes('.details')) {
      this.selectedFilter = '1'
      this.selectedMenu = Number(this.selectedFilter)
    }

    if (this.$route.name === 'individual.discovery.index') {
      this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: searchType } }).catch(() => { })
    }
  },
  computed: {
    menuOptions () {
      return [
        { to: { name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'positions' } }, text: 'global:positions', icon: 'mdi-briefcase' },
        { to: { name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'contents' } }, text: 'global:contents', icon: 'mdi-book-open-page-variant' },
        { to: { name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'courses' } }, text: 'global:courses', icon: 'mdi-school' }
      ]
    },
    isSearching () {
      return Object.keys(this.searchParameters).length > 0
    },
    breadcrumbsPaths () {
      const path = [{ text: 'main.header:discovery', href: this.$route.path.split('/').slice(0, -1).join('/') }]
      this.isSearching && path.push({ text: 'global:search', href: this.$route.path })
      return path
    },
    searchResultSample () {
      return this.$store.getters.getFilteredListLength
    }
  },
  methods: {
    showModalList (params) {
      this.contentId = params
      this.showAddList = !this.showAddList
    },
    showModalContentDetails (params) {
      this.contentId = params
      this.showContentModal = !this.showContentModal
    },
    changeFilter (filter) {
      this.searchParameters = filter
      this.textualSearch = this.searchParameters.search
      this.sendFilter()
    },
    sendFilter () {
      const searchType = this.selectedFilter === '0' ? 'positions' : this.selectedFilter === '1' ? 'contents' : 'courses'
      if (Object.keys(this.generateQueryFilter()).length > 0) {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: searchType, ...this.generateQueryFilter() } }).catch(() => { })
      } else {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: searchType } }).catch(() => { })
      }
    },
    clearFilter () {
      const searchType = this.selectedFilter === '0' ? 'positions' : 'contents'
      this.searchParameters = {}
      this.textualSearch = ''
      this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: searchType } }).catch(() => { })
    },
    onClickSearch () {
      if (this.textualSearch) {
        if (this.textualSearch.trim() === '') return
        this.searchParameters.search = this.textualSearch
        this.sendFilter()
      }
    },
    generateQueryFilter () {
      const finalFilter = {}
      for (const key in this.searchParameters) {
        finalFilter[key] = this.searchParameters[key]
      }
      return finalFilter
    },
    closeAdvancedSearch (preventPush = false) {
      const searchType = this.selectedFilter === '0' ? 'positions' : 'contents'
      this.showAdvancedSearch = false
      !preventPush && this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: searchType } }).catch(() => { })
    },
    switchSelectedFilter (which = '0') {
      this.selectedFilter = which
      this.showAdvancedSearch = false
      setTimeout(() => {
        this.showAdvancedSearch = true
      }, 100)
    }
  },
  watch: {
    '$route.query': {
      handler (search) {
        const filters = { ...this.$route.query }
        delete filters.type
        if (filters.contractType) {
          filters.contractType = Array.isArray(filters.contractType) ? filters.contractType : [filters.contractType]
        }
        if (filters.contentTypes) {
          filters.contentTypes = Array.isArray(filters.contentTypes) ? filters.contentTypes : [filters.contentTypes]
        }
        if (filters.skills) {
          filters.skills = Array.isArray(filters.skills) ? filters.skills.map(el => JSON.parse(el)) : [JSON.parse(filters.skills)]
        }
        if (filters.duration) {
          filters.duration = JSON.parse(filters.duration)
        }
        this.searchParameters = { ...this.initialSearchParameters, ...filters }
        if (this.$route.query.type !== undefined) {
        } else {
          this.selectedFilter = this.selectedMenu.toString()
          this.textualSearch = ''
        }
      },
      deep: true,
      immediate: true
    },
    $route: {
      handler: function (to, from) {
        if (from !== undefined) {
          if (from.name === 'individual.discovery.index.default.list.details') {
            this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'contents', categories: 'list' } }).catch(() => { })
            this.selectedFilter = '1'
            this.selectedMenu = Number(this.selectedFilter)
          }

          if (from.name === 'individual.discovery.index.default.content.details') {
            this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'contents' } }).catch(() => { })
            this.selectedFilter = '1'
            this.selectedMenu = Number(this.selectedFilter)
          }
        }
      },
      deep: true,
      immediate: true
    },
    selectedMenu () {
      this.selectedFilter = this.selectedMenu.toString()
      this.textualSearch = ''
    }
  }
}
</script>
<template>
  <section class="individual-discovery--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ $t('discovery.filter:title') }}</h2>
    </div>
    <div class="individual-discovery--body">
      <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
      <div class="default-content">
        <p>{{ $t('discovery.filter:description') }}</p>
        <div class="discovery-filter">
          <input-field v-model="textualSearch" style="flex-basis: 100%" class="modal-advanced-search--text-search mr-2"
            :placeholder="$t(`individual.discovery.filter:field.search.input.${selectedFilter}`)" outlined buttonHeight
            @keydown.enter='onClickSearch()' append-icon="mdi-magnify" @click:append="onClickSearch()" hideValidation
            clearable></input-field>
          <v-btn class="btn transform-unset" dark :color="getPrimaryColor" @click="showAdvancedSearch = true"><v-icon
              class="mr-1">mdi-tune</v-icon>{{ $t('global:filter') }}</v-btn>

        </div>
        <modal-search :value="searchParameters" :selectedFilter="selectedFilter === '0'? '0' : '1'" :filterName="selectedFilter === '0' ? 'positions' : 'courses'"
          :show="showAdvancedSearch && (selectedFilter === '0' || selectedFilter === '2')" @close="showAdvancedSearch = false"
          @changeSelectedFilter="switchSelectedFilter('1')"></modal-search>
        <modal-advanced-search v-model="searchParameters" :selectedFilter="selectedFilter" @search="changeFilter"
          :show="showAdvancedSearch && selectedFilter === '1'" @changeSelectedFilter="switchSelectedFilter('0')"
          @close="closeAdvancedSearch"></modal-advanced-search>
        <div>
          <applied-filters v-if="!!isSearching" :totalItems="filteredListLength" :filters="searchParameters"
            :queryRoute="selectedFilter === '0' ? 'positions' : 'contents'" @applyFilter="changeFilter" />
          <router-view @showModalAddListContent="params => showModalList(params)"
            @showModalContentDetails="params => showModalContentDetails(params)" />
          <modal-content-details-vue v-if="showContentModal" @close="showModalContentDetails"
            :contentIdProp="contentId"></modal-content-details-vue>
          <modal-content-list-association-vue v-if="showAddList" @hideModalAddListContent="showModalList()"
            :contentIdProp="contentId"></modal-content-list-association-vue>
        </div>
      </div>
    </div>
</section>
</template>
<style lang="scss">
.individual-discovery--container {

  .individual-discovery--body {

    p {
      margin-top: 20px;
    }

  }

  .discovery-filter {
    display: flex;
    justify-content: space-between;

    .modal-advanced-search--text-search {
      margin-bottom: 20px;

      .v-input__slot,
      .v-input__control,
      .v-input {
        height: 40px;
        min-height: 40px;

        &.v-input--is-focused {
          .v-input__append-inner:nth-last-child(1) {
            .v-icon {
              color: $neutral-white !important;
            }
          }
        }
      }

      .v-input__append-inner {
        margin-top: 8px;
        padding-right: 4px;
      }

      .v-input__append-inner:nth-last-child(1) {
        background: $primary-medium;
        margin: 0 -12px 0 0;
        border-radius: 0 4px 4px 0;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .v-icon {
          color: $neutral-white;
        }
      }
    }
  }
}
</style>
