<script>
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
import Autocomplete from '@/components/general/Autocomplete'

export default {
  name: 'ModalSearch',
  components: {
    InputField,
    SelectField,
    Autocomplete
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({ ...this.initialFilter })
    },
    hideSwitch: {
      type: Boolean,
      default: false
    },
    selectedFilter: {
      type: String
    },
    filterName: {
      type: String
    }
  },
  data () {
    return {
      categoryList: [],
      institute: [],
      citySearch: '',
      cityOptions: [],
      stateSearch: '',
      stateOptions: [],
      coursesProviders: [],
      coursesSegments: [],
      initialFilter: {
        selectedFilter: '0',
        search: null,
        category: null,
        city: null,
        state: null,
        remoteWorkOnly: false,
        contractType: [],
        remuneration: '0',
        undefinedSalary: true,
        exclusivePCD: false,
        institute: null,
        segment: null,
        modality: [],
        workload: null,
        pricing: null
      },
      mountedFilter: { ...this.value },
      priceOptions: [
        { text: this.$t('discovery.modal.search:workload.indifferent'), value: 'indifferent' },
        { text: this.$t('discovery.modal.search:pricing.free'), value: 'free' },
        { text: this.$t('discovery.modal.search:pricing.paid'), value: 'paid' }
      ],
      workloadOptions: [
        { text: this.$t('discovery.modal.search:workload.indifferent'), value: 'indifferent' },
        { text: this.$t('discovery.modal.search:workload.shortterm'), value: 'shortterm' },
        { text: this.$t('discovery.modal.search:workload.longterm'), value: 'longterm' }
      ]
    }
  },
  created () {
    if (this.$route.fullPath.indexOf('&') > -1) this.filedFilter()
  },
  computed: {
    fixedRegion () {
      const companyDomain = this.getCompanyDomainAndWorkspace().CompanyDomain
      return companyDomain === 'empregosagro' ? 'BRA' : this.getUserInfo.region
    },
    getUser () {
      return this.$store.getters.getUser
    },
    positionsCategory () {
      return this.categoryList.map(category => ({
        text: category.title,
        value: category.id
      }))
    },
    items () {
      return this.entries.map(entry => {
        const Description = entry.Description.length > this.descriptionLimit
          ? entry.Description.slice(0, this.descriptionLimit) + '...'
          : entry.Description
        return Object.assign({}, entry, { Description })
      })
    },
    hasSearchParams () {
      return !!Object.keys(this.filter()).length
    },
    isIndividual () {
      return !!this.$route.name.includes('individual')
    }
  },
  watch: {
    'mountedFilter.search' (val) {
      if (!val) return
      this.mountedFilter.search = val.trim()
    },
    show () {
      this.clearFilter(this.value)
      if (this.show === true) {
        Promise.all([
          this.$store.dispatch('attempGetCourseProviders', { aggregator: this.getCompanyDomainAndWorkspace() }),
          this.$store.dispatch('attempGetCourseSegments', { aggregator: this.getCompanyDomainAndWorkspace() }),
          this.$store.dispatch('attemptGetPositionsCategory', this.getUser.language)
        ]).then(([providers, segments, categories]) => {
          this.categoryList = categories
          this.coursesProviders = providers.map(provider => ({
            text: provider.name,
            value: provider._id
          }))
          this.coursesSegments = segments.map(segment => ({
            text: segment.name,
            value: segment._id
          }))
        })
      }
    },
    value () {
      this.clearFilter(this.value)
    },
    stateSearch (input) {
      this.debounceEvent(async () => {
        this.$store.dispatch('attempGetState', { country: this.fixedRegion, input }).then(data => {
          this.stateOptions = data.map(item => ({ text: item.name, value: item.id }))
        })
      }, 1000)
    },
    citySearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attempGetCity', { country: this.fixedRegion, input })
        if (options.length) this.cityOptions = options.map(item => ({ text: item.name, value: item.id }))
      }, 1000)
    },
    'mountedFilter.selectedFilter' (val) {
      this.clearFilter({ selectedFilter: val })
    },
    selectedFilter () {
      this.initialFilter.selectedFilter = this.selectedFilter
      this.mountedFilter.selectedFilter = this.selectedFilter
    }
  },
  methods: {
    filter () {
      const filter = {}
      Object.keys(this.mountedFilter).forEach(searchKey => {
        if (searchKey === 'isTrusted') return
        if (searchKey === 'selectedFilter') return
        const searchItem = this.mountedFilter[searchKey]

        if (this.$route.query.undefinedSalary && searchKey === 'undefinedSalary') {
          this.mountedFilter.undefinedSalary = this.$route.query.undefinedSalary === 'true'
        }
        if (searchKey === 'undefinedSalary' && !searchItem) filter[searchKey] = this.mountedFilter[searchKey]
        if (searchItem === 'indifferent' && (searchKey === 'pricing' || searchKey === 'workload')) return
        if (searchItem && Array.isArray(searchItem) && searchItem.length > 0) filter[searchKey] = this.mountedFilter[searchKey]
        else if (searchItem && searchItem !== '0' && !Array.isArray(searchItem)) filter[searchKey] = this.mountedFilter[searchKey]
      })
      return filter
    },
    close () {
      this.$emit('close')
    },
    clearFilter (custom = {}) {
      let state = {}
      let city = {}

      if (custom.state && custom.state.includes('{')) state = { state: JSON.parse(custom.state)[0].id }
      if (custom.city && custom.city.includes('{')) city = { city: JSON.parse(custom.city)[0].id }

      this.mountedFilter = { ...this.initialFilter, ...custom, ...city, ...state }
    },
    submitFilter () {
      this.clearFilter({ ...this.filter(), selectedFilter: this.mountedFilter.selectedFilter })
      const searchType = this.mountedFilter.selectedFilter === '0' ? 'positions' : 'courses'
      this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: searchType, ...this.generateQueryFilter() } }).catch(() => { })
      this.$emit('change', this.mountedFilter)
      this.close()
    },
    filedFilter () {
      this.clearFilter({ ...this.filter(), selectedFilter: this.mountedFilter.selectedFilter })
      this.generateQueryFilter()
      this.$emit('change', this.mountedFilter)
      this.close()
    },
    generateQueryFilter () {
      const finalFilter = {}
      for (const key in this.mountedFilter) {
        if (this.mountedFilter[key] !== this.initialFilter[key]) {
          if (key === 'state') finalFilter[key] = JSON.stringify(this.stateOptions.filter(s => s.value === this.mountedFilter[key]).map(s => ({ id: s.value, text: s.text })))
          if (key === 'city') finalFilter[key] = JSON.stringify(this.cityOptions.filter(c => c.value === this.mountedFilter[key]).map(c => ({ id: c.value, text: c.text })))
          if (key !== 'state' && key !== 'city') finalFilter[key] = this.mountedFilter[key]
        }
      }
      return finalFilter
    }
  },
  mounted () {
    if (this.selectedFilter) {
      this.initialFilter.selectedFilter = this.selectedFilter
      this.mountedFilter.selectedFilter = this.selectedFilter
    }
  }
}
</script>
<template>
  <v-dialog :value="show" max-width="100%" @click:outside="close" @keydown.esc="close" width="750px">
    <v-card class="modal-search--container">
      <div class="modal-search--header">
        <h3>{{ filterName ? $t('global:filter') + ': ' + $t(`global:${filterName}`).toLowerCase() : $t('global:filter')
          + ': ' + '' }}</h3>
        <v-icon class="clickable" color="#454545" @click="close">mdi-close</v-icon>
      </div>
      <div class="modal-search--filter">
        <div class="modal-search--filter-option">
          <h4>{{ $t('global:search') }}</h4>
          <input-field class="modal-search--text-search" v-model="mountedFilter.search"
            :placeholder="$t(`discovery.modal.search:search.text.placeholder.${filterName}`)" outlined buttonHeight
            append-icon="mdi-magnify"></input-field>
        </div>
        <div v-show="mountedFilter.selectedFilter === '0'">
          <div class="modal-search--filter-option">
            <h4>{{ $t('discovery.modal.search:categories.title') }}</h4>
            <select-field v-model="mountedFilter.category" :items="positionsCategory"
              :label="$t('discovery.modal.search:categories')" outlined small hide-validation clearable />
          </div>
          <div class="modal-search--filter-option">
            <h4>{{ $t('discovery.modal.search:workplace.title') }}</h4>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <autocomplete :search-input.sync="stateSearch" :items="stateOptions" v-model="mountedFilter.state"
                  hide-validation outlined small clearable :no-data-text="$t('global:no.data.text')"
                  :placeholder="$t('discovery.modal.search:workplace.states')">
                </autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <autocomplete color="red" :search-input.sync="citySearch" :items="cityOptions"
                  v-model="mountedFilter.city" hide-validation outlined small clearable
                  :no-data-text="$t('global:no.data.text')" :placeholder="$t('discovery.modal.search:workplace.cities')">
                </autocomplete>
              </v-col>
            </v-row>
            <v-switch :color="getPrimaryColor" v-model="mountedFilter.remoteWorkOnly" class="body--1 mt-4"
              :label="$t('discovery.modal.search:workplace.remote.only')" hide-details="auto"></v-switch>
          </div>
          <div class="modal-search--filter-option">
            <h4>{{ $t('discovery.modal.search:contract.type.title') }}</h4>
            <div class="modal-search--columns">
              <div>
                <v-checkbox v-model="mountedFilter.contractType" value="FULL_TIME"
                  :label="$t('discovery.modal.search:contract.type.FULL_TIME')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
                <v-checkbox v-model="mountedFilter.contractType" value="PART_TIME"
                  :label="$t('discovery.modal.search:contract.type.PART_TIME')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
                <v-checkbox v-model="mountedFilter.contractType" value="CONTRACTOR"
                  :label="$t('discovery.modal.search:contract.type.CONTRACTOR')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
                <v-checkbox v-model="mountedFilter.contractType" value="TEMPORARY"
                  :label="$t('discovery.modal.search:contract.type.TEMPORARY')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
              </div>
              <div>
                <v-checkbox v-model="mountedFilter.contractType" value="INTERN"
                  :label="$t('discovery.modal.search:contract.type.INTERN')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
                <v-checkbox v-model="mountedFilter.contractType" value="VOLUNTEER"
                  :label="$t('discovery.modal.search:contract.type.VOLUNTEER')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
                <v-checkbox v-model="mountedFilter.contractType" value="PER_DIEM"
                  :label="$t('discovery.modal.search:contract.type.PER_DIEM')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
                <v-checkbox v-model="mountedFilter.contractType" value="OTHER"
                  :label="$t('discovery.modal.search:contract.type.OTHER')" hide-details="auto"
                  :color="getPrimaryColor"></v-checkbox>
              </div>
              <div></div>
            </div>
          </div>
          <div class="modal-search--filter-option">
            <h4>{{ $t('discovery.modal.search:remuneration.title') }}</h4>
            <p>{{ $t('discovery.modal.search:remuneration.text') }}</p>
            <div class="modal-search--input">
              <p>R$</p>
              <input-field
                v-model="mountedFilter.remuneration"
                single-line
                type="number"
                :placeholder="$t('discovery.modal.search:remuneration.placeholder')"
                outlined
                buttonHeight
                @keypress="isNumber($event)"
              ></input-field>
            </div>
            <v-switch :color="getPrimaryColor" v-model="mountedFilter.undefinedSalary" :false-value="true"
              :true-value="false" class="body--1 mt-4" :label="$t('discovery.modal.search:undefined.salary')"
              hide-details="auto"></v-switch>
          </div>
          <div class="modal-search--filter-option mb-4 no-border">
            <h4>{{ $t('discovery.modal.search:accessibility.title') }}</h4>
            <v-switch :color="getPrimaryColor" v-model="mountedFilter.exclusivePCD" class="body--1 mt-4"
              :label="$t('discovery.modal.search:accessibility')" hide-details="auto"></v-switch>
          </div>
        </div>
        <div v-show="mountedFilter.selectedFilter === '1'">
          <div class="modal-search--filter-option">
            <h4>{{ $t('discovery.modal.search:institute.title') }}</h4>
            <select-field v-model="mountedFilter.institute" :items="coursesProviders"
              :label="$t('discovery.modal.search:institute')" outlined small hide-validation />
          </div>
          <div class="modal-search--filter-option">
            <h4>{{ $t('discovery.modal.search:segment.title') }}</h4>
            <select-field v-model="mountedFilter.segment" :items="coursesSegments"
              :label="$t('discovery.modal.search:segment')" outlined small hide-validation />
          </div>
          <div class="modal-search--filter-option">
            <h4>{{ $t('global:modality.title') }}</h4>
            <v-checkbox v-model="mountedFilter.modality" value="bimodal"
              :label="$t('discovery.modal.search:modality.bimodal')" hide-details="auto"
              :color="getPrimaryColor"></v-checkbox>
            <v-checkbox v-model="mountedFilter.modality" value="hybrid"
              :label="$t('discovery.modal.search:modality.hybrid')" hide-details="auto"
              :color="getPrimaryColor"></v-checkbox>
            <v-checkbox v-model="mountedFilter.modality" value="online"
              :label="$t('discovery.modal.search:modality.online')" hide-details="auto"
              :color="getPrimaryColor"></v-checkbox>
            <v-checkbox v-model="mountedFilter.modality" value="online_synchronous"
              :label="$t('discovery.modal.search:modality.realtime')" hide-details="auto"
              :color="getPrimaryColor"></v-checkbox>
            <v-checkbox v-model="mountedFilter.modality" value="oncampus"
              :label="$t('discovery.modal.search:modality.presential')" hide-details="auto"
              :color="getPrimaryColor"></v-checkbox>
          </div>
          <div class="modal-search--filter-option workload">
            <h4>{{ $t('discovery.modal.search:workload.title') }}</h4>
            <v-radio-group v-model="mountedFilter.workload" mandatory>
              <v-radio v-for="(workload, index) in workloadOptions" :key="index" :label="workload.text"
                :value="workload.value" :color="getPrimaryColor"></v-radio>
            </v-radio-group>
          </div>
          <div class="modal-search--filter-option no-border">
            <h4>{{ $t('global:value') }}</h4>
            <v-radio-group v-model="mountedFilter.pricing" mandatory>
              <v-radio v-for="(price, index) in priceOptions" :key="index" :label="price.text" :value="price.value"
                :color="getPrimaryColor"></v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
      <div class="modal-search--actions-apply">
        <v-btn class="btn mr-4" retain-focus-on-click outlined :color="getPrimaryColor" @click="clearFilter">{{
          $t('advanced.filter:clear.filter')
        }}</v-btn>
        <v-btn class="btn" :dark="hasSearchParams" :disabled="!hasSearchParams" :color="getPrimaryColor"
          @click="submitFilter"><v-icon color="#fff" size="20">mdi-magnify</v-icon>{{
            $t(`advanced.filter:find.${filterName}`)
          }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-search--container {
  position: relative;

  .modal-search--header {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    background-color: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    h3 {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  .modal-search--filter {
    padding: 0 24px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    padding-bottom: 40px;

    .modal-search--filter-option {
      text-align: left;
      padding: 32px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      .modal-search--text-search {
        .v-icon {
          margin-bottom: 15px;
        }
      }

      .modal-search--columns {
        display: flex;
        justify-content: space-between;
      }

      .modal-search--input {
        display: flex;
        align-items: center;

        p {
          margin: 0 8px 0 0;
        }
      }

      .v-input--selection-controls {
        margin-top: 0;
        margin-bottom: 16px;
      }

      h4 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 16px;
      }
    }

    .workload {
      padding-bottom: 0;
    }
  }

  .modal-search--actions-apply {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);

    .btn.v-btn.v-size--default {
      text-transform: none;
      height: 36px;
      font-size: 14px;
    }
  }
}
</style>
