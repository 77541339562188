<script>
export default {
  name: 'Accordion'
}
</script>
<template>
  <v-expansion-panels
    v-bind="$attrs"
    v-on="$listeners"
    flat
  >
    <slot></slot>
  </v-expansion-panels>
</template>
