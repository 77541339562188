<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'AppliedFilters',
  props: {
    queryRoute: {
      type: String
    },
    filters: {
      type: Object,
      required
    },
    totalItems: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      contractType: [
        { text: 'discovery.modal.search:contract.type.integral', key: 'FULL_TIME' },
        { text: 'discovery.modal.search:contract.type.half.period', key: 'PART_TIME' },
        { text: 'discovery.modal.search:contract.type.3rd.party', key: 'CONTRACTOR' },
        { text: 'discovery.modal.search:contract.type.temporary', key: 'TEMPORARY' },
        { text: 'discovery.modal.search:contract.type.volunteer', key: 'VOLUNTEER' },
        { text: 'discovery.modal.search:contract.type.internship', key: 'INTERN' },
        { text: 'discovery.modal.search:contract.type.diary', key: 'PER_DIEM' },
        { text: 'discovery.modal.search:contract.type.other', key: 'OTHER' }
      ],
      modalityLabel: [
        { text: 'discovery.modal.search:modality.bimodal', key: 'bimodal' },
        { text: 'discovery.modal.search:modality.hybrid', key: 'hybrid' },
        { text: 'discovery.modal.search:modality.online', key: 'online' },
        { text: 'discovery.modal.search:modality.realtime', key: 'online_synchronous' },
        { text: 'discovery.modal.search:modality.presential', key: 'oncampus' }
      ],
      workloadLabel: [
        { text: 'discovery.modal.search:workload.indifferent', key: 'indifferent' },
        { text: 'discovery.modal.search:workload.shortterm', key: 'shortterm' },
        { text: 'discovery.modal.search:workload.longterm', key: 'longterm' }
      ],
      pricingLabel: [
        { text: 'discovery.modal.search:workload.indifferent', key: 'indifferent' },
        { text: 'discovery.modal.search:pricing.free', key: 'free' },
        { text: 'discovery.modal.search:pricing.paid', key: 'paid' }
      ]
    }
  },
  computed: {
    formatedFilter () {
      const filter = []
      const modifiedFilter = { ...this.filters }
      if (modifiedFilter.contentTypes) {
        modifiedFilter.contentTypes = Array.isArray(modifiedFilter.contentTypes) ? modifiedFilter.contentTypes : [modifiedFilter.contentTypes]
      }
      Object.keys(modifiedFilter).map(key => {
        if (Array.isArray(modifiedFilter[key])) {
          return modifiedFilter[key].map(subItem => {
            if (key === 'contentTypes') {
              const category = this.getContentTypeAlias(subItem)
              filter.push({ key, text: this.$t('content.type:' + category), id: subItem })
            } else if (key === 'skills') {
              filter.push({ key, text: subItem.title, id: subItem.id })
            } else if (key === 'contractType') {
              filter.push({ key, text: this.$t(`discovery.modal.search:contract.type.${subItem}`), id: subItem })
            } else {
              filter.push({ key, text: subItem })
            }
          })
        }

        if (key === 'state' || key === 'city') {
          const obj = JSON.parse(this.filters[key])[0]
          filter.push({ key, text: obj.text })
        } else if (key === 'contractType') {
          filter.push({ key, text: this.$t(`discovery.modal.search:contract.type.${this.filters.contractType}`) })
        } else {
          filter.push({ key, text: this.filters[key] })
        }
      })
      return filter
    },
    hasFilter () {
      return Object.keys(this.filters).length > 0
    }
  },
  methods: {
    removeFilterItem (key, text, id) {
      const filter = {}
      Object.keys(this.filters).map((itemKey) => {
        if (itemKey !== key) {
          if (itemKey === 'skills') {
            filter[itemKey] = this.filters[itemKey].map(el => JSON.stringify(el))
          } else if (itemKey === 'duration') {
            filter[itemKey] = typeof this.filters[itemKey] === 'string' ? this.filters[itemKey] : JSON.stringify(this.filters[itemKey])
          } else {
            filter[itemKey] = this.filters[itemKey]
          }
        }
        if (Array.isArray(this.filters[itemKey]) && this.filters[itemKey].length > 1 && itemKey === key) {
          if (key === 'contentTypes') {
            filter[itemKey] = this.filters[itemKey].filter(item => item !== id)
          } else if (key === 'skills') {
            filter[itemKey] = this.filters[itemKey].filter(item => item.id !== id).map(skill => JSON.stringify(skill))
          } else if (key === 'contractType') {
            filter[itemKey] = this.filters[itemKey].filter(item => item !== id)
          } else {
            filter[itemKey] = this.filters[itemKey].filter(item => item !== text)
          }
        }
      })
      this.$emit('applyFilter', filter)
    },
    clearFilter () {
      this.$emit('applyFilter', {})
    },
    getContentTypeAlias (contentTypeId) {
      const { alias } = this.$store.getters.getContentTypes.find(contentType => contentType.id === parseInt(contentTypeId))
      return alias
    }
  }
}
</script>
<template>
  <div class="advanced-filter--applied-filters-container" :class="{ 'is-mobile': isMobile }">
    <h4 class="advanced-filter--title">{{ $t('applied.filter:title') }}</h4>
    <p v-if="totalItems > -1 && hasFilter">{{ $t('applied.filter:total.items', { num: totalItems }) }}</p>
    <div class="chips-container">
      <template v-for="filter in formatedFilter">
        <v-chip :key="(filter.key + filter.text)">
          {{ $te(`advanced.filter:active.filter.${filter.key }.${ filter.text } `) ?
          $t(`advanced.filter:active.filter.${ filter.key }.${ filter.text }`) : parseTextFormat(filter)
          }}
          <v-icon class="remove-item" @click="removeFilterItem(filter.key, filter.text, filter.id)">mdi-close</v-icon>
        </v-chip>
      </template>
      <v-btn v-if="hasFilter" text small class="clear-filter bolder" color="#454545" @click="clearFilter">{{
      $t('advanced.filter:clear.filter')
      }}</v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.advanced-filter--applied-filters-container {
  text-align: left;
  margin: 30px 0 16px;

  .advanced-filter--title {
    font-family: $lato;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #242321;
    margin-bottom: 8px;
  }

  .v-chip {
    background: #FFFFFF !important;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 8px;
    border-radius: 9999px;
    color: #454545;
    font-weight: bold;
    margin-right: 10px;

    .v-icon.remove-item {
      cursor: pointer;
      background: #454545;
      color: #FFFFFF;
      border-radius: 50px;
      margin-left: 6px;
      height: 20px;
      width: 20px;
      font-size: 18px;
    }
  }

  .clear-filter {
    display: inline-block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: capitalize;
  }

  .chips-container {
    align-items: center;
  }

  &.is-mobile {
    .chips-container {
      overflow: auto;
      align-items: center;

      .v-chip {
        flex-shrink: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;

    .v-btn.clear-filter {
      min-width: auto;
      padding: 0;
    }
  }
}
</style>
