<script>
export default {
  name: 'ContentListAssociationTable',
  components: {
    ConfirmationModal: () => import('@/components/general/ConfirmationModal')
  },
  data () {
    return {
      isFirstUpdate: false,
      header: [
        { text: this.$t('content.list.assoc.modal.header:title'), value: 'title', width: '95%' },
        { text: this.$t('content.list.assoc.modal.header:action'), value: 'action', width: '5%' }
      ],
      lists: this.items,
      showRemoveModal: false,
      itemToRemove: null
    }
  },
  props: {
    items: { type: Array, default: () => [] },
    updatePage: Function,
    page: Number,
    itemsPerPage: Number
  },
  computed: {},
  methods: {
    showPaginationText ({ pageStart, pageStop, itemsLength }) {
      return `${pageStart}-${pageStop} ${this.$t('skill.modal.pagination.preposition')} ${itemsLength}`
    },
    removeConfirmation () {
      this.$emit('removeItem', this.itemToRemove)
      this.itemToRemove = null
      this.showRemoveModal = false
    }
  }
}
</script>

<template>
  <div>
    <v-data-table
      :headers="header"
      :items="lists.filter(list => !list.isDeleted)"
      :items-per-page="itemsPerPage"
      :page="page"
      @update:page="updatePage"
      disable-sort
      no-data-text=""
      :footer-props="{ 'items-per-page-options': [itemsPerPage] }"
      item-key="id"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon @click="showRemoveModal = true, (itemToRemove = item)" class="warning-medium">mdi-delete</v-icon>
      </template>
      <template v-slot:[`footer.page-text`]="data">
        {{ showPaginationText(data) }}
      </template>
    </v-data-table>
    <confirmation-modal
      :show="showRemoveModal"
      @confirm="removeConfirmation"
      :title="$t('content.list.assoc.modal.remove:title')"
      :description="$t('content.list.assoc.modal.remove:info')"
      :confirmText="$t('global:remove')"
      :btnConfirmText="false"
      :cancelText="$t('global:cancel')"
      :maxWidth="500"
      @cancel="showRemoveModal = false"
    ></confirmation-modal>
  </div>
</template>
<style lang="scss">
.theme--dark.v-btn.mx-2.submit-skills:not(.v-btn--flat) {
  background-color: $primary-medium;
}
.table-header {
  color: $neutral-dark;
  font-size: 12px;
}
.v-icon.warning-medium {
  color: $warning-medium;
}
.v-data-footer {
  padding: 25px 0;

  .v-data-footer__icons-before,
  .v-data-footer__icons-after {
    margin-left: 5px;
    border-radius: 4px;
  }
}
.overflow-text {
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  font-size: 16px;
  font-family: $lato;
}
</style>
