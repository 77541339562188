<script>
import ContentListAssociationTable from './ContentListAssociationTable'
import ModalManageList from '../lists/ModalManageList.vue'
export default {
  name: 'ModalContentListAssociation',
  components: {
    ContentListAssociationTable,
    ModalManageList
  },
  props: {
    contentIdProp: {
      type: Object
    }
  },
  data () {
    return {
      content: null,
      selectedList: null,
      isSaving: false,
      addedItems: [],
      allLists: [],
      tablePage: 1,
      tableItemsPerPage: 5,
      availableLists: [],
      showAddNewListModal: false,
      showModalAssociation: true
    }
  },
  computed: {
    contentId () {
      if (this.contentIdProp === undefined) {
        return this.$route.params.contentId
      } else {
        return this.contentIdProp.contentId
      }
    },
    resizedLists () {
      return this.allLists
        .filter(all => this.listsToSync.findIndex(sync => (sync.id === all.id && !sync.isDeleted)) === -1)
        .map(list => {
          return list.title.length < 50
            ? list : { ...list, title: list.title.slice(0, 50) + '...' }
        })
    },
    dividedLists () {
      return this.addHeadersToList(this.resizedLists)
    },
    listsToSync () {
      const contentLists = this.content && this.content.lists && this.content.lists.length ? this.availableLists.filter(list => {
        return list.contents.find(content => content.contentId === this.content.id)
      }) : []
      return [...contentLists, ...this.addedItems]
    }
  },
  methods: {
    handleSelectedList (list) {
      const listIdx = this.listsToSync.findIndex(syncList => syncList.id === list.id)
      if (listIdx === -1) {
        this.addedItems.push(list)
      } else {
        this.listsToSync[listIdx].isDeleted = false
      }
      this.allLists = this.allLists.filter(listToShow => listToShow.id !== list.id)
      const addedListsToSync = this.listsToSync.filter(list => !list.isDeleted)
      this.updatePage(Math.ceil(addedListsToSync.length / this.tableItemsPerPage))
      this.$nextTick(() => {
        this.selectedList = null
        this.$refs.listSearch.blur()
      })
    },
    customFilter (item, query) {
      return item.header ? false : item.title.toLocaleLowerCase().indexOf(query.toLocaleLowerCase().trim()) > -1
    },
    removeList (list) {
      const listIdx = this.listsToSync.findIndex(syncList => syncList.id === list.id)
      if (listIdx !== -1) {
        this.listsToSync[listIdx].isDeleted = true
      }
      this.allLists.push(list)
    },
    close () {
      this.addedItems = []
      this.listsToSync.map(item => (item.isDeleted = false))
      this.updateOptionsLists()
      this.tablePage = 1
      if (this.contentIdProp !== undefined) {
        this.$emit('hideModalAddListContent')
      } else {
        this.$router.go(-1)
      }
    },
    save () {
      this.isSaving = true
      this.$store.dispatch('attemptAssociateContentWithList', {
        contentId: this.content.id,
        lists: this.listsToSync
          .filter(list => list && !this.objIsEmpty(list))
          .map(list => ({ id: list.id, isDeleted: list.isDeleted }))
      })
        .then(() => {
          this.$store.dispatch('attemptGetMyselfContents')
          this.$store.dispatch('attemptGetMyselfLists')
          this.close()
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('content.list.assoc.modal:toast.submit.success')
          })
        })
        .catch((err) => {
          const knownErrors = ['provided_content_could_not_added_to_others_workspaces']
          let errorMessage = null
          if (knownErrors.includes(err.error)) {
            errorMessage = this.$t('content.list.assoc.modal:toast.submit.error.' + err.error)
          } else {
            errorMessage = this.$t('content.list.assoc.modal:toast.submit.error')
          }
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: errorMessage
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    updatePage (page) {
      this.tablePage = page
    },
    updateOptionsLists () {
      if (this.content && this.content.lists && this.content.lists.length) {
        this.allLists = this.availableLists.filter(listToShow => !this.content.lists.some(list => list.id === listToShow.id))
      } else {
        this.allLists = this.availableLists
      }
    },
    appendList ({ data: newList }) {
      this.addedItems.push(newList)
    }
  },
  created () {
    // if (!this.contentId && !this.contentIdProp) {
    //   this.$router.push({ name: this.selectedWorkspace.type + '.home.user' })
    // }
    this.$store.dispatch('attemptGetOwnedLists')
      .then((data) => {
        this.availableLists = data.filter(list => this.userCanManage(list.workspaceId))
      })
      .finally(() => {
        const getterContent = this.$store.getters.getUserContents.find(item => item.id === this.contentId)
        if (getterContent) {
          this.content = getterContent
          this.updateOptionsLists()
        } else {
          this.$store.dispatch('attemptGetContentById', this.contentId).then(({ data }) => {
            this.content = data
            this.updateOptionsLists()
          })
        }
      })
  }
}
</script>

<template>
  <v-dialog
    :value="showModalAssociation"
    persistent
    max-width="600"
    content-class="modal-content-association--container"
  >
    <v-card class="content-list-assoc--wrapper">
      <span class="top-right"><v-btn icon @click="close" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn></span>
      <v-container>
        <h5 class="text-left h7 bolder">{{ $t('global:add.to.list') }}</h5>
         <v-autocomplete
          :items="dividedLists"
          @input="handleSelectedList"
          :filter="customFilter"
          v-model="selectedList"
          item-text="listTitle"
          :item-value="item => item"
          outlined
          :label="$t('content.list.assoc.modal:input.lists')"
          class="mt-10"
          ref="listSearch"
          attach
        >
          <template v-slot:no-data>
            <p class="text-center lato neutral-medium font-16 mt-5">{{ $t('content.list.assoc.modal:empty.lists') }}</p>
            <p class="text-center lato neutral-medium font-16 mt-n5">{{ $t('content.list.assoc.modal:empty.lists.warning') }}</p>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          <template v-slot:append-item>
            <div class="h-divider neutral-light"></div>
            <v-list-item class="add-list-append-item mt-n5" @click="(showAddNewListModal = true)">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t('content.list.assoc.modal:append.item')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <div>
          <h5 class="text-left h8 bolder">{{ $t('content.list.assoc.modal:added.lists') }}</h5>
          <p class="lato neutral-medium font-16 mt-5 mb-10" v-if="!this.listsToSync.filter(list => !list.isDeleted).length">{{ $t('content.list.assoc.modal:empty.added.lists') }}</p>
          <content-list-association-table
            v-else
            :key="Math.random()"
            :items="listsToSync"
            @removeItem="removeList"
            class="mt-5"
            :updatePage="updatePage"
            :page="tablePage"
            :itemsPerPage="tableItemsPerPage"
          />
        </div>
        <div class="card-content-actions text-right">
          <v-btn text @click="close" color="#1200D3" class="btn transform-unset"><b>{{ $t('global:cancel') }}</b></v-btn>
          <v-btn @click="save" :disabled="listsToSync.length === 0" :dark="listsToSync.length !== 0" :loading="isSaving" color="#1200D3" class="btn transform-unset ml-5"><b>{{ $t('global:save') }}</b></v-btn>
        </div>
      </v-container>
    </v-card>
    <modal-manage-list
      instanced
      :show="showAddNewListModal"
      @close="(showAddNewListModal = false)"
      @onCreated="appendList"
    />
  </v-dialog>
</template>

<style lang="scss">
.modal-content-association--container {
  overflow: auto;
  z-index: 253 !important;
  .content-list-assoc--wrapper {
    .v-subheader.theme--light {
    font-weight: 900;
    @extend .font-16;
  }
  .v-list-item__title {
    @extend .font-16;
  }
    padding: 10px 10px 0px 10px;
    .card-content-actions {
      margin-right: 0;
    }
  }
  .add-list-append-item {
    cursor: pointer;
    width: 100%;
  }
}
</style>
