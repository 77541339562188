<script>
import ShareModal from '@/components/general/ShareModal'
import ImageCardType from '@/components/general/ImageCardType'
export default {
  name: 'ModalContentDetails',
  components: {
    ImageCardType,
    ShareModal,
    ConfirmationModal: () => import('@/components/general/ConfirmationModal'),
    ModalForbiddenResource: () =>
      import('@/components/general/ModalForbiddenResource')
  },
  props: {
    contentIdProp: {
      type: Object
    }
  },
  data () {
    return {
      showConfirmToggleConclusion: false,
      showRemovalModal: false,
      showAllSkills: false,
      showNotAllowedModal: false,
      loading: false,
      shareModal: false,
      content: null,
      actionOptions: ['edit', 'delete'],
      iconsMap: {
        course: 'mdi-laptop',
        video: 'mdi-play-circle-outline',
        article: 'mdi-text-box',
        podcast: 'mdi-microphone',
        slides: 'mdi-collage',
        book: 'mdi-book',
        event: 'mdi-calendar',
        questionnaire: 'mdi-list-status'
      }
    }
  },
  computed: {
    getDefaultImageByContentType () {
      return `/assets/images/background-${(this.content && this.content.contentType && this.content.contentType.alias) || 'article'}.png`
    },
    getTitleIcon () {
      return this.iconsMap[this.content.contentType.alias]
    },
    getFirstSkills () {
      return this.content.skills
        .slice(0, 6)
        .map((skill) =>
          skill.title.length > 50
            ? { ...skill, title: skill.title.slice(0, 50) + '...' }
            : skill
        )
    },
    getAllSkills () {
      return this.content.skills.map((skill) =>
        skill.title.length > 50
          ? { ...skill, title: skill.title.slice(0, 50) + '...' }
          : skill
      )
    },
    remainingSkills () {
      return this.content.skills.slice(6).length
    },
    routeName () {
      return this.$route.name
    },
    ownerName () {
      return `${this.content.owner.name} ${this.content.owner.lastName || ''}`
    }
  },
  methods: {
    showContentLink () {
      window.open(this.content.link, '_blank')
      this.$store.dispatch('attemptSaveAccess', this.content.id)
    },
    directToRoute () {
      this.$router.push(this.getBackRoute())
    },
    close () {
      this.updateLearningInProgress()
      if (this.$route.params.contentId !== undefined) this.directToRoute()
      this.$emit('close')
      this.$emit('showModal')
    },
    removeContent () {
      this.showRemovalModal = false
      this.$store
        .dispatch('attemptRemoveContent', this.content.id)
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch('attemptGetMyselfContents')
            this.$store.dispatch('attemptGetMyselfLists')
          }
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('content:toast.delete.error')
          })
        })
        .finally(() => {
          this.directToRoute()
        })
    },
    executeAction (action) {
      const params = { contentId: this.content.id }
      const routesToPush = [
        { dir: 'discovery', path: this.selectedWorkspace.type + '.discovery.index.content.manage', params },
        {
          dir: 'business.contents.published.content',
          path: this.selectedWorkspace.type + '.contents.published.content.manage',
          params
        },
        {
          dir: 'business.contents.published.lists',
          path: this.selectedWorkspace.type + '.contents.published.lists.manage',
          params
        },
        { dir: 'default', path: this.selectedWorkspace.type + '.home.user.contents.published.content.manage', params }
      ]
      switch (action) {
        case 'edit':
          this.$emit('close')
          this.pushToRouteName(routesToPush)
          break

        case 'delete':
          this.showRemovalModal = true
          break
      }
    },
    toggleConclusionModal () {
      this.showConfirmToggleConclusion = !this.showConfirmToggleConclusion
    },
    applyToggleContentConclusion () {
      this.$store
        .dispatch('attemptSetContentInteraction', { contentId: this.content.id, interaction: 'conclusion' })
        .then(({ data }) => {
          if (data) {
            this.content.interactionType = data.currentStatus
            this.$store.dispatch('attemptUpdateContentItem', this.content)
          }
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t(
              `content.conclusion.toggle:success.${this.content.interactionType === 'conclusion' ? 'done' : 'undo'
              }`
            )
          })
        })
        .finally(() => {
          this.toggleConclusionModal()
        })
    },
    loadContent () {
      this.loading = true
      const params = this.$route.params.contentId === undefined ? this.contentIdProp.contentId : this.$route.params.contentId

      this.$store
        .dispatch('attemptGetContentById', params)
        .then(({ data }) => {
          this.content = data
        })
        .catch((err) => {
          if (err.code === 403 && err.error === 'not_allowed') {
            this.showNotAllowedModal = true
          } else {
            this.directToRoute()
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('content:error.load')
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateLearningInProgress () {
      const availableStatus = ['conclusion', 'in_progress']
      availableStatus.map((status) => {
        const params = {
          filter: { status },
          userId: this.$store.getters.getUser.userId
        }
        this.$store.dispatch('attemptGetFilteredUserLists', params)
        this.$store.dispatch('attemptGetFilteredUserContents', params)
      })
    },
    addToList () {
      const params = { contentId: this.content.id }
      this.$router.push({ name: `${this.$route.name}.association`, params })
    },
    handleKey (event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    handleOutsideClick () {
      this.close()
    },
    toggleInProgress () {
      this.$store
        .dispatch('attemptSetContentInteraction', { contentId: this.content.id, interaction: 'in_progress' })
        .then(({ data }) => {
          this.content.interactionType = data.currentStatus
        })
        .finally(() => {
          this.updateLearningInProgress()
        })
    },
    showShareModal () {
      this.shareModal = true
    },
    closeShareModal () {
      this.shareModal = false
    },
    shareDetails () {
      return {
        link: this.getBaseUrl() + `/discovery/content/details/${this.content && this.content.id}`
      }
    }
  },
  created () {
    this.loadContent()
    this.$emit('hideModal')
  }
}
</script>

<template>
  <div>
    <modal-forbidden-resource v-if="showNotAllowedModal" />
    <v-dialog :value="true" persistent no-click-animation width="800" content-class="v-dialog-content-detail"
      @click:outside="handleOutsideClick" @keydown="handleKey">
      <div v-if="loading"></div>
      <v-card v-else class=content-details--wrapper>
        <span class="top-right fixed black-shallow">
          <v-btn icon @click="close" color="#FFF">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>
        <div class="content-details--image-wrapper">
          <div class="content-details--image-overlay"></div>
          <v-img height="327px" :src="content.coverImageURL || getDefaultImageByContentType">
          </v-img>
        </div>
        <v-container class="content-details-header pa-5">
          <v-row>
            <v-col sm="12" md="12">
              <v-chip color="#FFF" class="h7 content-chip">
                <v-icon left>
                  {{ getTitleIcon }}
                </v-icon>
                {{ $t(`content.type:${content.contentType.alias}`) }}
              </v-chip>
            </v-col>
            <v-col sm="12" md="12">
              <h5 class="content-details--title h6">{{ content.title }}</h5>
            </v-col>
          </v-row>
          <div class="content-details--owner-container">
            <v-avatar size="36" color="indigo">
              <v-img :src="content.owner.imageUrl || defaultAvatarUrl('profile')" />
            </v-avatar>
            <span class="lato font-16 neutral-darkest content-details--owner-name ml-3">{{ ownerName }}</span>
          </div>
        </v-container>
        <v-container class="content-details--body-wrapper">
          <v-row class="content-details--actions-wrapper">
            <v-col md="12" class="content-details--info-container">
              <div class="content-details--infos">
                <v-icon color="black">
                  mdi-clock-outline
                </v-icon>
                <p class="body--1 neutral-darkest">{{ totalDurationByMs(content.duration) }}</p>
              </div>
              <div class="content-details--infos">
                <v-icon color="black">
                  mdi-wrench
                </v-icon>
                <p class="body--1 neutral-darkest">
                  {{ $t(`skill.modal.levels:${content.level}`) }}
                </p>
              </div>
            </v-col>
            <v-btn class="btn btn-primary ml-2" @click="showContentLink">
              {{ $t('global:access') }}
            </v-btn>
            <v-btn class="btn ml-2"
              :class="{ 'btn-primary btn-outlined': content.interactionType !== 'in_progress', 'btn-success-medium': content.interactionType === 'in_progress' }"
              :outlined="content.interactionType !== 'in_progress'"
              :color="content.interactionType === 'in_progress' ? '#2AD3BE' : '#1200D3'" @click="toggleInProgress">
              <v-icon class="mr-2">{{ content.interactionType === 'in_progress' ? 'mdi-play-circle' :
                'mdi-play-circle-outline' }}</v-icon>
              {{ content.interactionType === 'in_progress' ? $t('global:in.progress') : $t('global:initiate') }}
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="btn btn-primary ml-2 spaceButtons"
                  :class="{ 'btn-outlined': content.interactionType !== 'conclusion' }"
                  :color="content.interactionType === 'conclusion' ? '#16AC99' : '#1200D3'" @click="toggleConclusionModal"
                  v-bind:outlined="content.interactionType !== 'conclusion'" v-bind="attrs" v-on="on">
                  <v-icon class="mr-1" :color="content.interactionType === 'conclusion' ? '#ffff' : '#1200D3'"
                    v-if="content.interactionType === 'conclusion'">mdi-check-circle-outline</v-icon>
                  <span>{{ content.interactionType === 'conclusion' ? $t('content.modal.details:concluded') :
                    $t('content.modal.details:conclude') }}</span>
                </v-btn>
              </template>
              <span>{{ content.interactionType === 'conclusion' ? $t('content.toggle.conclusion.tooltip:undo') :
                $t('content.toggle.conclusion.tooltip:set.conclusion') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#1200D3" class="learning-card--share-button mx-2 spaceButtons" v-on="on" v-bind="attrs"
                  @click="showShareModal">mdi-share-variant</v-icon>
              </template>
              <span>{{ $t('content:share') }}</span>
            </v-tooltip>
            <v-menu bottom left absolute transition="slide-x-transition" min-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon class="mx-1 spaceButtons" v-on="on" v-bind="attrs">
                  <v-icon class="icon-primary">mdi-plus-box-multiple-outline</v-icon>
                </v-btn>
              </template>
              <v-list dense class="my-0">
                <v-list-item dense @click="addToList">
                  {{ $t("global:add.to.list") }}
                </v-list-item>
              </v-list>
            </v-menu>
            <template v-if="userManagesContent(content.workspace.id)">
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon class="mx-3" v-bind="attrs" v-on="on">
                    <v-icon class="icon-primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(action, i) in actionOptions" :key="i" @click="executeAction(action)">
                    <v-list-item-title>{{ $t(`content.card.action.options:${action}`) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-row>
          <v-row>
            <v-container>
              <v-container class="h8 mb-n4 px-2">
                {{ $t('global:description') }}
              </v-container>
              <v-container v-if="content.description" class="body--1 text-left pr-10 px-2">
                {{ content.description }}
              </v-container>
              <v-container v-else class="lato font-16 neutral-medium mt-1">
                {{ $t('modal.content:empty.description') }}
              </v-container>
            </v-container>
          </v-row>
          <v-row>
            <v-container v-if="getFirstSkills.length > 0">
              <v-container class="h8 mb-n4 px-2">
                {{ $t('global:skills') }}
              </v-container>
              <v-container class="px-1">
                <v-btn rounded class="btn-secondary ma-1 skill-wrapper"
                  v-for="skill in (showAllSkills ? getAllSkills : getFirstSkills)" :key="skill.id">
                  <span class="skill-text"> {{ skill.title }} </span>
                </v-btn>
                <v-btn v-if="remainingSkills > 0 && !showAllSkills" fab small outlined class="btn-outlined-primary mx-1"
                  @click="showAllSkills = true">
                  +{{ remainingSkills }}
                </v-btn>
              </v-container>
            </v-container>
          </v-row>
        </v-container>
        <confirmation-modal :show="showRemovalModal" :title="$t('content.remove:title')"
          :description="$t('content.remove:info')" :confirmText="$t('global:delete')" :btnConfirmText="false"
          @confirm="removeContent" :cancelText="$t('global:cancel')" @cancel="showRemovalModal = false" />
        <confirmation-modal :show="showConfirmToggleConclusion"
          :title="content.interactionType === 'conclusion' ? $t('content.card.modal.conclusion:undo.toggle.title') : $t('content.card.modal.conclusion:toggle.title')"
          :description="content.interactionType === 'conclusion' ? $t('content.card.modal.conclusion:undo.toggle.description') : $t('content.card.modal.conclusion:toggle.description')"
          :confirmText="$t('global:confirm')" @confirm="applyToggleContentConclusion" :cancelText="$t('global:cancel')"
          @cancel="toggleConclusionModal"></confirmation-modal>
      </v-card>
      <share-modal v-model="shareModal" :details="shareDetails()" @close="closeShareModal">
        <image-card-type :card="content" :defaultImage="getDefaultImageByContentType" />
      </share-modal>
    </v-dialog>
    <router-view />
  </div>
</template>

<style lang="scss">
.v-dialog-content-detail {
  z-index: 252 !important;
}

.content-details--wrapper {
  position: relative;
  .top-right {
    .v-icon.mdi {
      color: $neutral-white;
    }
  }

  .content-details-header {
    position: absolute;
    top: 170px;
    left: 0;
    background: linear-gradient(to bottom,
        rgba(255, 255, 255, 0) 0px,
        rgba(255, 255, 255, 0) 150px,
        rgba(255, 255, 255, 1) 150px,
        rgba(255, 255, 255, 1) 100%);
    text-align: left;
    z-index: 15;

    .content-details--owner-container {
      margin-top: 15px;

      .v-avatar {
        margin-left: 0;
      }
    }

    .content-chip {
      color: $primary-medium;
    }

    @media screen and (max-width: 768px) {
      top: 40px;

      .row {
        display: flex;
        flex-direction: column;

        >div {
          padding-top: 0;
        }
      }
    }

    .content-details--owner-name {
      align-self: center;
    }
  }

  .skill-wrapper {
    max-width: 100%;
  }

  .skill-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.content-details--image-wrapper {
  position: relative;

  .content-details--image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 1));
    z-index: 10;

    @media screen and (max-width: 768px) {
      background: linear-gradient(to bottom,
          rgba(0, 0, 0, 0.3) 0,
          rgba(255, 255, 255, 1));
    }
  }

  .v-img.v-responsive {
    z-index: 5;
  }

  @media screen and (max-width: 768px) {
    max-height: 160px;
    overflow: hidden;
  }
}

.content-details--info-container {
  display: flex;
  padding: 8px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    flex-direction: column;
  }
}
.content-details--infos {
  display: inline;
  vertical-align: middle;
  margin-right: 35px;
  margin-bottom: 40px;

  >* {
    display: inline;
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.content-details--actions-wrapper {
  display: inline;
  vertical-align: middle;
  margin-left: 5px;
}

.content-details--body-wrapper {
  text-align: left;
}

.content-details--title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-family: $lato;
  font-weight: bold;
  font-size: 26px;
}

.btn-outlined {
  font-family: $lato;
  font-size: $font-size-small;
  font-weight: bold;
}

@media only screen and (max-width: 409px) {
  .content-details--body-wrapper {
    .btn-primary {
      margin-bottom: 20px;
    }

    .mx-3.v-btn:last-child {
      margin-top: 0px !important;
    }

    .spaceButtons {
      margin-bottom: 10px;
    }

  }

}

@media only screen and (max-width: 768px) {
  .content-details--body-wrapper {
    .mx-3.v-btn:last-child {
      margin-top: 40px;
    }

    .spaceButtons {
      margin-top: 10px;
    }

  }

  .content-details--infos:first-child {
    margin-top: 25px;

  }

  .content-details--infos:nth-child(2) {
    margin-top: 0px;

  }
}
</style>
