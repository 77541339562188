<script>
import ValidationTemplate from '@/components/general/ValidationTemplate'
export default {
  name: 'Autocomplete',
  components: { ValidationTemplate },
  props: {
    validation: {
      type: Object
    },
    manualValidation: {
      type: Boolean,
      default: false
    },
    hideValidation: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValidation () {
      if (!this.manualValidation && this.validation) this.validation.$touch()
    }
  }
}
</script>
<template>
  <validation-template
    :validation="validation"
    :manualValidation="manualValidation"
    :hideValidation="hideValidation"
  >
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      hide-details="auto"
      class="autocomplete--container body--1"
      :class="{'small': small, 'primary-color': primary}"
      attach
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    </v-autocomplete>
  </validation-template>
</template>
<style lang="scss">
.autocomplete--container {
  &.small {
    &.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
      min-height: 42px;
      padding: 0;
    }
    &.v-text-field--outlined>.v-input__control>.v-input__slot {
      min-height: 48px;
      input {
        padding: 0;
      }
    }
    &.v-text-field .v-input__append-inner {
      margin-top: 13px;
    }
    .v-chip {
      background: $primary-medium;
      color: $neutral-white;
      border-radius: 4px;
      height: 26px;
      .v-icon {
        color: $neutral-white;
      }
    }
  }
  &.primary-color {
    &.theme--light.v-text-field--outlined>.v-input__control>.v-input__slot fieldset,
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
      border-color: $primary-medium-faded !important;
    }
  }
}
</style>
