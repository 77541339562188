<script>
export default {
  name: 'Breadcrumbs',
  computed: {
    avatar () {
      if (this.root?.avatar) return this.root.avatar
      const workspace = this.selectedWorkspace
      if (workspace.type === 'individual') {
        return '/assets/images/icon.png'
      } else {
        return workspace.avatar
      }
    },
    formattedItems () {
      const isAdminCenter = this.$route.name.includes('business.admin.center')
      const text = this.root?.text || (isAdminCenter ? 'home.header.options:admin.center' : 'main.header:home')
      const href = this.root?.href || this.$router.resolve({ name: this.selectedWorkspace.type + (isAdminCenter ? '.admin.center.index' : '.home') }).href
      return [{
        text,
        href,
        avatar: true
      }, ...(this.items.map((item, i, { length }) => ({
        text: item.text,
        href: item.href,
        disabled: (length - 1 === i)
      })))]
    }
  },
  props: {
    items: {
      type: Array
    },
    root: {
      type: Object
    }
  }
}
</script>
<template>
  <div class="breadcrumbs--container">
    <v-breadcrumbs :items="formattedItems">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click.prevent="$router.push({ path:item.href })" :href="item.href" :disabled="item.disabled" >
          <img class="breadcrumbs--icon" :src="avatar" :alt="$t(item.text)" v-if="item.avatar" />
          <span v-else>{{ $t(item.text) }}</span>
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>
<style lang="scss">
.breadcrumbs--container {
  margin-bottom: 15px;

  .v-breadcrumbs {
    padding: 0;

    li {
      font-size: 13px;
      line-height: 20px;

      &:first-child {
        margin-right: 5px;
      }

      &:nth-child(2n) {
        padding: 0 1px;
      }

      .v-icon {
        font-size: 13px;
      }

      a {
        color: #777;
      }

      .v-breadcrumbs__item--disabled {
        font-weight: bold;
        color: #777;
      }
    }
  }

  .breadcrumbs--icon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
}
</style>
