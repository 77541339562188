<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'AccordionItem',
  props: {
    title: {
      type: String,
      required
    },
    titleClass: {
      type: String
    }
  }
}
</script>
<template>
  <v-expansion-panel class="accordion-item--container">
    <v-expansion-panel-header :class="titleClass">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<style lang="scss">
.accordion-item--container {
  margin: 8px 0;
  .v-expansion-panel-header {
    @extend .bolder;
    @extend .lato;
    min-height: 35px;
    font-size: 19px;
    color: #313131;
    padding: 0;
  }
  .v-input--selection-controls {
    margin: 10px 0;
    padding: 0;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  &.v-expansion-panel--active+.v-expansion-panel,
  &.v-expansion-panel--active {
    margin-top: 0px;
  }
}
</style>
