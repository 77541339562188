<script>
import Accordion from '@/components/general/Accordion'
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
import Autocomplete from '@/components/general/Autocomplete'
import AccordionItem from '@/components/general/AccordionItem'
export default {
  name: 'ModalAdvancedSearch',
  components: {
    Autocomplete,
    Accordion,
    AccordionItem,
    InputField,
    SelectField
  },
  data () {
    return {
      hasCleanUp: false,
      searchSkill: '',
      mounted: false,
      openPanels: [0, 1, 2, 3, 4, 5, 6],
      profileLocationSearch: '',
      profileLocationOptions: [],
      businessLocationSearch: '',
      businessLocationOptions: [],
      availableCategories: [
        { text: this.$t('global:contents'), value: 'content' },
        { text: this.$t('global:lists'), value: 'list' }
      ],
      durationType: [
        { text: this.$tc('global:minutes', 0), value: 'minutes' },
        { text: this.$tc('global:hours', 0), value: 'hours' },
        { text: this.$tc('global:days', 0), value: 'days' }
      ],
      formData: {
        shouldWatch: {
          categories: [],
          skills: [],
          contentTypes: [],
          levels: [],
          languages: [],
          location: null,
          businessLocation: null,
          activitiesSectors: [],
          position: null,
          company: null
        },
        selectedFilter: null,
        search: null,
        duration: {
          from: null,
          to: null,
          type: 'minutes'
        }
      },
      preventSearch: true
    }
  },
  props: {
    value: Object,
    shouldCaching: Boolean,
    selectedFilter: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'formData.search' (val) {
      this.formData.search = val.trim()
    },
    'formData.shouldWatch.skills' (val) {
      if (val.length > 10) {
        this.formData.shouldWatch.skills = val.slice(0, 10)
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('advanced.filter:skills.limit.legth')
        })
      }
    },
    show () {
      this.formatIncomeData()
    },
    searchSkill () {
      this.$store.dispatch('attemptGetAvailableSkills', { limit: 99999 })
    },
    // value: {
    //   handler () {
    //     this.preventSearch = true
    //     this.$nextTick(() => {
    //       this.formatIncomeData()
    //     })
    //   }
    // },
    profileLocationSearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetProfileLocations', input)
        this.profileLocationOptions = options.map(item => ({ text: item.location }))
      }, 900)
    },
    businessLocationSearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetBusinessLocations', input)
        this.businessLocationOptions = options.map(item => ({ text: item.location }))
      }, 900)
    }
  },
  computed: {
    canSearch () {
      if (this.hasSearchParams) return true
      else {
        if (this.hasCleanUp) return true
        return false
      }
    },
    hasSearchParams () {
      return Object.keys(this.cleanupFilter()).length > 0
    },
    contentTypes () {
      return this.$store.getters.getContentTypes
    },
    availableSkills () {
      return this.$store.getters.getAvailableSkills
    },
    availableSectors () {
      return this.enumActivitySector.map(sector => {
        return {
          text: this.$t(`activity.sector.default:${sector.toLowerCase()}`),
          value: sector
        }
      })
    },
    emptyFormDataState () {
      return {
        shouldWatch: {
          categories: [],
          skills: [],
          contentTypes: [],
          levels: [],
          languages: [],
          location: null,
          businessLocation: null,
          activitiesSectors: [],
          position: null,
          company: null
        },
        selectedFilter: null,
        search: null,
        duration: {
          from: null,
          to: null,
          type: 'minutes'
        }
      }
    },
    isIndividual () {
      return !!this.$route.name.includes('individual')
    }
  },
  methods: {
    clearFormData () {
      this.formData.shouldWatch = {
        categories: [],
        skills: [],
        contentTypes: [],
        levels: [],
        languages: [],
        location: null,
        businessLocation: null,
        activitiesSectors: [],
        position: null,
        company: null
      }
      this.formData.selectedFilter = null
      this.formData.search = null
      this.formData.duration = {
        from: null,
        to: null,
        type: 'minutes'
      }
    },
    handleArrayByUrl (value) {
      if (value) {
        value = Array.isArray(value) ? value : [value]
      }
      if (value !== undefined) {
        for (let i = 0; i < value.length; i++) {
          value[i] = parseInt(value[i])
        }
      }
      return value
    },
    formatIncomeData () {
      this.$set(this.formData.shouldWatch, 'categories', this.value.categories || [])
      this.$set(this.formData.shouldWatch, 'skills', this.value.skills || [])
      this.$set(this.formData.shouldWatch, 'contentTypes', this.handleArrayByUrl(this.value.contentTypes) || [])
      this.$set(this.formData.shouldWatch, 'levels', this.value.levels || [])
      this.$set(this.formData.shouldWatch, 'languages', this.value.languages || [])
      this.$set(this.formData.shouldWatch, 'activitiesSectors', this.value.activitiesSectors || [])
      this.formData.search = this.value.search || null
      this.formData.duration.from = (this.value.duration && this.value.duration.from) || null
      this.formData.duration.to = (this.value.duration && this.value.duration.to) || null
      this.formData.duration.type = (this.value.duration && this.value.duration.type) || 'minutes'
      this.formData.shouldWatch.location = this.value.location || null
      this.formData.shouldWatch.businessLocation = this.value.businessLocation || null
      this.formData.shouldWatch.position = this.value.position || null
      this.formData.shouldWatch.company = this.value.company || null
      this.$nextTick(() => {
        this.preventSearch = false
      })
    },
    cleanupFilter () {
      const cleanFilter = {}
      Object.keys(this.formData).map(key => {
        if (['shouldWatch', 'duration'].includes(key)) return
        if (this.formData[key] && this.formData[key].length) {
          cleanFilter[key] = this.formData[key]
        }
      })
      Object.keys(this.formData.shouldWatch).map(key => {
        if (this.formData.shouldWatch[key] && this.formData.shouldWatch[key].length) {
          cleanFilter[key] = this.formData.shouldWatch[key]
        }
      })

      if (this.formData.duration.to || this.formData.duration.from) {
        cleanFilter.duration = JSON.stringify(this.formData.duration)
      }
      if (this.formData.shouldWatch.skills) {
        const formatedSkills = this.formData.shouldWatch.skills.map(el => JSON.stringify(el))
        // cleanFilter.skills = formatedSkills
        if (formatedSkills.length) cleanFilter.skills = formatedSkills
      }
      return cleanFilter
    },
    search (preventSearch = false) {
      this.hasCleanUp = false
      if (!this.hasSearchParams && !preventSearch) this.$emit('close')
      !preventSearch && this.$emit('search', this.cleanupFilter())
      !preventSearch && this.close(true)
    },
    clearFilter (shouldClose = true) {
      // this.$emit('input', {})
      // this.$emit('search', {})
      this.hasCleanUp = true
      this.clearFormData()
      // if (shouldClose) this.close()
    },
    close (preventPush = false) {
      this.hasCleanUp = false
      this.$emit('close', preventPush)
    },
    clearTextualSearch () {
      this.formData.search = null
      this.search(false)
    }
  },
  created () {
    this.$store.dispatch('attemptGetContentTypes')
    if (this.$store.getters.getShouldCachingFilters) {
      this.formData = { ...this.emptyFormDataState, ...this.$store.getters.getAppliedAdvancedFilters }
      if (this.formData.shouldWatch.location) {
        this.profileLocationOptions = [{ text: this.formData.shouldWatch.location }]
      }
      if (this.formData.shouldWatch.businessLocation) {
        this.businessLocationOptions = [{ text: this.formData.shouldWatch.businessLocation }]
      }
      // this.search(true)
    } else {
      this.clearFilter(false)
      this.formData = { ...this.emptyFormDataState }
      this.$store.getters.getIndividualWorkspace.languages && this.$store.getters.getIndividualWorkspace.languages.forEach(preferredLang => {
        this.formData.shouldWatch.languages.push(preferredLang.alias)
      })
      this.search(this.preventSearch)
    }
  },
  mounted () {
    this.mounted = true
    if (this.formData?.shouldWatch?.languages?.length) {
      this.openPanels.push(2)
    }
    if (this.selectedFilter) {
      // this.formData.selectedFilter = this.selectedFilter
      this.formatIncomeData()
    }
  }
}
</script>
<template>
  <v-dialog :value="show" width="750px" max-width="100%" @click:outside="close" @keydown.esc="close">
    <v-card @click:outside="close">
      <div class="modal-advanced-search--container">
        <div class="modal-advanced-search--header">
          <h2 class="modal-advanced-search--title">{{ $t('modal.advanced.search:title') }}</h2>
          <v-icon class="clickable" @click="close">mdi-close</v-icon>
        </div>

        <div class="modal-content--content--list">
          <div class="modal-advanced-search--body">
            <input-field v-model="formData.search" class="modal-advanced-search--text-search" outlined
              :placeholder="$t('advanced.search:text.search.placeholder')" append-icon="mdi-magnify"
              @click:append="search(false)" @click:clear="clearTextualSearch" @keydown.enter="search(false)"
              hideValidation clearable></input-field>
            <accordion multiple v-model="openPanels">
              <accordion-item titleClass="h8" :title="$t('advanced.search:categories.title')">
                <v-checkbox v-for="category in availableCategories" class="body--1"
                  v-model="formData.shouldWatch.categories" :key="category.value" :label="category.text"
                  hide-details="auto" :value="category.value" :color="getPrimaryColor"></v-checkbox>
              </accordion-item>
              <accordion-item titleClass="h8" :title="$t('advanced.search:skills.title')">
                <autocomplete v-model="formData.shouldWatch.skills" chips deletable-chips :items="availableSkills"
                  :item-value="item => item" item-text="title"
                  :placeholder="$t('advanced.search:skill.input.placeholder')" :no-data-text="$t('global:no.data.text')"
                  :search-input.sync="searchSkill" multiple outlined small hideValidation primary clearable
                  @change="searchSkill = ''"></autocomplete>
              </accordion-item>
              <accordion-item titleClass="h8" :title="$t('advanced.search:language.title')">
                <autocomplete v-model="formData.shouldWatch.languages" chips deletable-chips
                  :placeholder="$t('advanced.search:language.input.placeholder')"
                  :no-data-text="$t('global:no.data.text')" :items="availableLanguages" item-text="text"
                  item-value="value" hideValidation multiple outlined primary small clearable></autocomplete>
              </accordion-item>
              <div class="h-divider"></div>

              <h2 class="modal-advanced-search--sub-title h7">{{ $t('advanced.search:section.content.and.list.title') }}
              </h2>

              <accordion-item titleClass="h8" :title="$t('advanced.search:types.title')">
                <v-checkbox v-for="category in contentTypes" class="body--1" v-model="formData.shouldWatch.contentTypes"
                  :key="category.alias" :label="$t(`content.type:${category.alias}`)" hide-details="auto"
                  :value="category.id" :color="getPrimaryColor"></v-checkbox>
              </accordion-item>
              <accordion-item titleClass="h8" :title="$t('advanced.search:level.title')">
                <v-checkbox v-for="category in contentLevels" class="body--1" v-model="formData.shouldWatch.levels"
                  :key="category" :label="$t(`skill.modal.levels:${category}`)" hide-details="auto"
                  :value="category" :color="getPrimaryColor"></v-checkbox>
              </accordion-item>
              <accordion-item titleClass="h8" :title="$t('advanced.search:duration.title')">
                <div class="modal-advanced-search--duration-container">

                  <div class="modal-advanced-search--duration-time">
                    <input-field class="body--1 modal-advanced-search--duration-time-input" v-model="formData.duration.from"
                      :placeholder="$t('global:time.from', { num: 15 })" outlined small primary hideValidation @keypress="isNumber($event)"
                      clearable></input-field>
                    <input-field class="body--1 modal-advanced-search--duration-time-input" v-model="formData.duration.to"
                      :placeholder="$t('global:time.to', { num: 20 })" outlined small primary hideValidation @keypress="isNumber($event)"
                      clearable></input-field>
                  </div>

                  <div class="modal-advanced-search--duration-type">
                    <select-field :items="durationType" class="settings-language--select modal-advanced-search-duration-type--select-input" v-model="formData.duration.type"
                      hideValidation outlined small primary></select-field>
                    <v-btn color="#1200D3" @click="search()"><v-icon size="24px">mdi-check</v-icon></v-btn>
                  </div>
                </div>
              </accordion-item>
            </accordion>
          </div>
        </div>
      </div>
      <div class="modal-advanced-search--actions-apply">
        <v-btn class="btn mr-4 transform-unset" retain-focus-on-click outlined :color="getPrimaryColor" @click="clearFilter">{{
          $t('advanced.filter:clear.filter') }}</v-btn>
        <v-btn class="btn transform-unset white--text" :disabled="!canSearch" :color="getPrimaryColor"
          @click="search()"><v-icon color="#fff" size="20">mdi-magnify</v-icon>{{ $t('advanced.filter:find.contents')
          }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-advanced-search--container {
  .modal-advanced-search--header {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    background-color: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    h2.modal-advanced-search--title {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  .modal-advanced-search--body {
    padding: 24px 24px;
    padding-bottom: 40px;
  }

  .modal-search--filter-option {
    text-align: left;
    margin-bottom: 20px;

    h4 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 16px;
    }
  }

  .modal-advanced-search--text-search {
    margin-bottom: 20px;

    .v-input__slot,
    .v-input__control,
    .v-input {
      height: 40px;
      min-height: 40px;

      &.v-input--is-focused {
        .v-input__append-inner:nth-last-child(1) {
          .v-icon {
            color: $neutral-white !important;
          }
        }
      }
    }

    .v-input__append-inner {
      margin-top: 8px;
      padding-right: 4px;
    }

    .v-input__append-inner:nth-last-child(1) {
      background: $primary-medium;
      margin: 0 -12px 0 0;
      border-radius: 0 4px 4px 0;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .v-icon {
        color: $neutral-white;
      }
    }
  }

  .modal-advanced-search--sub-title {
    margin: 0 auto 20px 0;
    font-size: 21px;
  }

  .modal-advanced-search--duration-container {
    display: flex;
    justify-content: space-between;

    .modal-advanced-search--duration-time {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      margin-right: 5px;

      > :nth-child(1) {
        margin-right: 5px;
      }
    }

    .modal-advanced-search--duration-type {
      display: flex;
      justify-content: space-between;

      .v-text-field--outlined>.v-input__control>.v-input__slot {
        min-height: 48px;
        height: 48px;
      }

      margin-top: 5px;

      .v-btn {
        height: 48px;
        width: 48px;
        min-width: 40px;
        margin-left: 5px;

        .v-icon {
          font-size: 30px;
          color: white
        }
      }
    }

  }

  &.modal-advanced-search--floating.v-card {
    position: fixed;
    height: 100%;
    max-width: 90%;
    overflow: auto;
    z-index: 115;
    top: 0;
    right: 0;
    box-shadow: 0 2px 20px 0 black;
    padding-bottom: 60px;

    .modal-advanced-search--mobile-header {
      display: flex;
      justify-content: space-between;

      .v-btn {
        padding: 0;
        min-width: 32px;
      }

      .modal-advanced-search--sub-title {
        margin-bottom: 5px;
      }
    }

    .modal-advanced-search--mobile-actions {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 90%;
      z-index: 110;
      background: #FFFFFF;
      height: 56px;

      .mobile-action-btn.v-btn {
        @extend .bolder;
        height: 100%;
        width: 50%;
        border-radius: 0;

        &:nth-last-child(1) {
          color: $neutral-white;
        }
      }
    }
  }
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}

.modal-advanced-search--actions-apply {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0 24px;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 68px;
  background: #FAFAFA;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  z-index: 10;

  .btn.v-btn.v-size--default {
    text-transform: none;
    height: 36px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .v-pagination__more {
    display: none;
  }

  .modal-advanced-search--duration-container {
    display: flex;
    flex-direction: column;

    .modal-advanced-search--duration-time {
      .modal-advanced-search--duration-time-input{
        width: 100% !important;
      }
     }

    .modal-advanced-search--duration-type {
      .modal-advanced-search-duration-type--select-input {
        width: 100%;
      }
    }

  }
}
</style>
